import React, { useState } from "react";
import TabList from "@material-ui/lab/TabList";

import cx from "classnames";
import {
  Box,
  Checkbox,
  List,
  ListItem,
  ListItemIcon,
  ListItemSecondaryAction,
  makeStyles,
  Paper,
  Popover,
  Tab,
} from "@material-ui/core";
import { Close } from "@material-ui/icons";
import { useCommonStyles } from "../../../../utils/loan-application.styles";
import { primaryColor } from "../../../../scss/colors.scss";

import Button from "../Button";

const useStyles = makeStyles((theme) => ({
  tabsRoot: {
    borderBottom: "2px solid #e8e8e8",
  },
  indicator: {
    backgroundColor: primaryColor,
    height: "3px",
  },
  tabRoot: {
    padding: "10px 25px",
    textTransform: "none",
    minWidth: "unset",
    "& > span": {
      fontSize: "18px",
      lineHeight: "22px",
      width: "auto",
      fontWeight: "400",
    },
    [theme.breakpoints.down("xs")]: {
      padding: "0px",
      marginRight: "24px",
    },
  },
  tabPanelRoot: {
    "&.MuiTabPanel-root": {
      padding: "48px 0px ",
    },
  },
  disabledCheckbox: {
    "&.Mui-disabled": {
      color: primaryColor,
      borderColor: "unset",
    },
  },
  saveButton: {
    padding: "8px 16px",
  },
  closeIcon: {
    color: primaryColor,
    fontSize: "inherit",
    cursor: "pointer",
    "&:hover": {
      opacity: 0.8,
    },
  },
}));

const DynamicTabsList = ({
  tabLists,
  expandButtonTitle,
  onSelectTab,
  selectedTabValues,
  addedTabs,
  setAddedTabs,
  handleAddNewTab,
  displayTabs,
  isTabCloseable = false,
  onTabClose = () => {},
}) => {
  const classes = useStyles();
  const commonClasses = useCommonStyles();
  const [anchorEl, setAnchorEl] = useState(null);
  const isOpenDropdown = Boolean(anchorEl);

  const handleOpenDropdown = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseDropdown = async () => {
    await handleAddNewTab();
    setAnchorEl(null);
  };

  const handleSelectOption = (value) => {
    if (selectedTabValues.has(value)) {
      return;
    }
    const newTabsSet = new Set([...addedTabs]);
    if (newTabsSet.has(value)) {
      newTabsSet.delete(value);
    } else {
      newTabsSet.add(value);
    }
    setAddedTabs(newTabsSet);
  };

  const onTabChange = async (event, newValue) => {
    if (newValue === expandButtonTitle) {
      return;
    }
    await onSelectTab(event, newValue);
  };

  return (
    <>
      <TabList
        onChange={onTabChange}
        TabIndicatorProps={{ children: <span /> }}
        scrollButtons="desktop"
        variant="scrollable"
        classes={{
          root: cx(classes.tabsRoot, commonClasses.marginBottom24),
          indicator: classes.indicator,
        }}
      >
        {displayTabs.map((tabItem, index) => {
          if (tabItem.value === expandButtonTitle) {
            return (
              <Tab
                disableRipple
                // eslint-disable-next-line react/no-array-index-key
                key={`${index}_${tabItem.key}`}
                fullWidth={false}
                label={expandButtonTitle}
                value={expandButtonTitle}
                classes={{
                  root: classes.tabRoot,
                }}
                onClick={handleOpenDropdown}
              />
            );
          }
          return (
            <Tab
              disableRipple
              // eslint-disable-next-line react/no-array-index-key
              key={`${index}_${tabItem.key}`}
              fullWidth={false}
              label={
                <Box display="flex" alignItems="center" gridColumnGap="8px">
                  {tabItem.title}
                  {isTabCloseable && (
                    <Close
                      classes={{ root: classes.closeIcon }}
                      onClick={(e) => {
                        e.stopPropagation();
                        onTabClose(tabItem.value);
                      }}
                    />
                  )}
                </Box>
              }
              value={tabItem.value}
              classes={{
                root: classes.tabRoot,
              }}
            />
          );
        })}
      </TabList>
      <Popover
        open={isOpenDropdown}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        onClose={handleCloseDropdown}
      >
        <Paper>
          <List>
            {tabLists.map((option) => (
              <ListItem
                key={option.key}
                dense
                button
                onClick={() => handleSelectOption(option.value)}
              >
                <Box
                  display="flex"
                  justifyContent="space-between"
                  width="100%"
                  gridColumnGap="48px"
                  alignItems="center"
                >
                  {option.label}
                  <ListItemIcon
                    classes={{
                      root: commonClasses.disabledMinWidth,
                    }}
                  >
                    <Checkbox
                      color="secondary"
                      checked={
                        selectedTabValues.has(option.value) ||
                        addedTabs.has(option.value)
                      }
                      disabled
                      classes={{
                        root: cx(
                          addedTabs.has(option.value) &&
                            classes.disabledCheckbox
                        ),
                      }}
                    />
                  </ListItemIcon>
                </Box>
              </ListItem>
            ))}
            <ListItem dense>
              <Box minHeight="42px">
                <ListItemSecondaryAction>
                  <Button
                    classes={classes.saveButton}
                    onClick={handleCloseDropdown}
                  >
                    Save
                  </Button>
                </ListItemSecondaryAction>
              </Box>
            </ListItem>
          </List>
        </Paper>
      </Popover>
    </>
  );
};

export default DynamicTabsList;
